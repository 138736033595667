export const fiches_pap_es = {
  analyse: 'Análisis',
  identite: 'IDENTIDAD',
  nom: 'Nombre',
  genre: 'Tipo',
  date_naissance: 'Fecha de nacimiento',
  localite: 'Localidad',
  telephone: 'Teléfono',
  reference: 'REFERENCIA',
  categorie: 'Categoría',
  personne_affectee_par_le_projet: 'Persona afectada por el proyecto',
  numero_enquete: 'Número de paquete',
  inventaires: 'Inventarios',
  parcelles: 'Parcelas',
  proxies: '(ES)Proxies',
  is_proxy_of: '(ES)Is proxy of',
  is_proxy_by: '(ES)Is proxied by',
  socio_eco: 'SOCIO-ECO',
  activite_principale: 'Actividad principal',
  revenu_moyen: 'Ingresos medios',
  statut: 'Estatus',
  nombre_membre_menage: 'Número de miembros del hogar',
  vulnerable: 'Vulnerable',
  compensation: 'INDEMNIZACIÓN',
  eval_pertes: 'Eval. Pérdidas',
  documents: 'Documentos',
  feuille_de_bien: 'Ficha de la propiedad',
  signer: 'Firma',
  signature: 'Firma',
  selectionnez_votre_fichier: 'Seleccione su archivo',
  signature_importee: 'Firma importada',
  fichier_signe: 'Expediente firmado',
  annuler: 'Cancelar',
  ok: 'Ok',
  evaluation: 'Evaluación',
  numero: 'N°',
  contrat: 'Contrato',
  supprimer: 'Borrar',
  erreur_acces_fichier: 'Error de acceso al archivo',
  type: 'Tipo',
  quantite: 'Cantidad',
  montant: 'Importe',
  description: 'Descripción',
  type_de_bien: 'Tipo de propiedad',
  type_de_perte: 'Tipo de pérdida',
  en_nature: 'En especie',
  email: 'Envíe un correo electrónico a',
  creer_le_contrat: 'Crear el contrato',
  uploader_le_contrat_signe: 'Subir el contrato firmado',
  le_contrat_a_ete_cree: 'El contrato fue creado',
  selectionnez_un_pap: 'Seleccione un Parte Interesada',
  cni: 'CNI',
  prenom: 'Nombre',
  surnom: 'Apodo',
  partie_prenante: 'Partes interesadas',
  creer_un_pap: 'Crear un Parte Interasada',
  isPap: 'Es un PAP',
  erreur_upload_signature: 'Error al cargar la firma',
  paiements: 'Pagos',
  ajouter_quittance: '(ES)ajouter_quittance',
  fichier_quittance: '(ES)fichier_quittance',
  quittance_enregistree: '(ES)quittance_enregistree',
  regle_le: '(ES)regle_le',
  quittance: '(ES)quittance',
  activites: '(ES)Activite',

};
